<template>
  <div>
    <div id="parkingRevenue" class="parkingRevenue"></div>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
import { formatDuring } from "@/common/js/public.js";
export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return {};
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [], // "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"
      cityColor: [
        "#527BFF",
        "#FFB342",
        "#DB3132",
        "#C71585",
        "#008000",
        "#DAA520",
        "#00e888",
        "#00e7ef",
        "#C71585",
        "#008000",
        "#DAA520",
        "#00e888",
        "#00e7ef",
      ],
      cityNameList: [],
      seriesValue: [
        // {
        //   name: "北京市",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [120, 132, 101, 134, 90, 230, 210],
        //   lineStyle: {
        //     color: "#C71585",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#C71585",
        //     },
        //   },
        // },
        // {
        //   name: "天津市",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [220, 182, 191, 234, 290, 330, 310],
        //   lineStyle: {
        //     color: "#008000",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#008000",
        //     },
        //   },
        // },
        // {
        //   name: "广州市",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [150, 232, 201, 154, 190, 330, 410],
        //   lineStyle: {
        //     color: "#DAA520",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#DAA520",
        //     },
        //   },
        // },
        // {
        //   name: "北京市1",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [120, 132, 101, 134, 90, 230, 210],
        //   lineStyle: {
        //     color: "#C71585",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#C71585",
        //     },
        //   },
        // },
        // {
        //   name: "天津市1",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [220, 182, 191, 234, 290, 330, 310],
        //   lineStyle: {
        //     color: "#008000",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#008000",
        //     },
        //   },
        // },
        // {
        //   name: "广州市1",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [150, 232, 201, 154, 190, 330, 410],
        //   lineStyle: {
        //     color: "#DAA520",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#DAA520",
        //     },
        //   },
        // },
        // {
        //   name: "北京市2",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [120, 132, 101, 134, 90, 230, 210],
        //   lineStyle: {
        //     color: "#C71585",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#C71585",
        //     },
        //   },
        // },
        // {
        //   name: "天津市2",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [220, 182, 191, 234, 290, 330, 310],
        //   lineStyle: {
        //     color: "#008000",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#008000",
        //     },
        //   },
        // },
        // {
        //   name: "广州市2",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [150, 232, 201, 154, 190, 330, 410],
        //   lineStyle: {
        //     color: "#DAA520",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#DAA520",
        //     },
        //   },
        // },
        // {
        //   name: "北京市3",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [120, 132, 101, 134, 90, 230, 210],
        //   lineStyle: {
        //     color: "#C71585",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#C71585",
        //     },
        //   },
        // },
        // {
        //   name: "天津市3",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [220, 182, 191, 234, 290, 330, 310],
        //   lineStyle: {
        //     color: "#008000",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#008000",
        //     },
        //   },
        // },
        // {
        //   name: "广州市3",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [150, 232, 201, 154, 190, 330, 410],
        //   lineStyle: {
        //     color: "#DAA520",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#DAA520",
        //     },
        //   },
        // },
        // {
        //   name: "北京市4",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [120, 132, 101, 134, 90, 230, 210],
        //   lineStyle: {
        //     color: "#C71585",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#C71585",
        //     },
        //   },
        // },
        // {
        //   name: "天津市4",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [220, 182, 191, 234, 290, 330, 310],
        //   lineStyle: {
        //     color: "#008000",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#008000",
        //     },
        //   },
        // },
        // {
        //   name: "广州市4",
        //   type: "line",
        //   // stack: 'Total',
        //   data: [150, 232, 201, 154, 190, 330, 410],
        //   lineStyle: {
        //     color: "#DAA520",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#DAA520",
        //     },
        //   },
        // },
      ],
      textTitle: "",
    };
  },
  watch: {
    // tableData: {
    //   handler(value) {
    //     this.totalNum = 0;
    //     this.dataList = [];
    //     this.nameList = [];
    //     for (var i = 0; i < value.length; i++) {
    //       this.totalNum += value[i].value * 1;
    //       this.nameList.push(value[i].dataDate);
    //       this.dataList.push({
    //         name: value[i].name,
    //         value: Number(value[i].value / 100).toFixed(2),
    //       });
    //     }
    //     this.totalNum = (this.totalNum / 100).toFixed(2);
    //     this.drawGraph();
    //   },
    //   deep: true,
    // },
    // 营收总报表的营收趋势
    // paySummaryReporttableData: {
    //   handler(value) {
    //     this.totalNum = 0;
    //     let dateArr = [];
    //     this.dateList = [];
    //     this.seriesValue4[0].data = [];
    //     for (var i = 0; i < value.length; i++) {
    //       // this.totalNum += value[i].value * 1
    //       dateArr.push(value[i].dateDate);
    //       this.seriesValue4[0].data.push(
    //         Number(value[i].totalAmount / 100).toFixed(2)
    //       );
    //     }
    //     this.dateList = this.uniq(dateArr);
    //     this.drawGraph();
    //   },
    //   deep: true,
    // },
  },
  methods: {
    handleGetOverview() {
      let url = "/acb/2.0/bacb/parkOverview/parkIncome";
      this.$axios
        .get(url)
        .then((res) => {
          if (res.state == 0) {
            this.handleOverviewInit(res.value);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        })
        .catch((err) => {
          // this.$alert(err.desc, this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   })
        });
    },
    handleOverviewInit(data = []) {
      const dateList = [],
        seriesValue = [],
        cityNameList = [];
      if (data.length >= 1) {
        data.forEach((item) => {
          // 日期
          if (dateList.indexOf(item.date_day) == -1) {
            dateList.push(item.date_day);
          }
          // 城市、金额
          let seriesStatus = false;
          for (let i = 0; i < seriesValue.length; i++) {
            let seriesItem = seriesValue[i];
            if (seriesItem.name == item.areaname) {
              seriesValue[i].data.push(Number(item.actualpay / 100).toFixed(2));
              seriesStatus = true;
              break;
            }
          }
          if (!seriesStatus) {
            seriesValue.push({
              name: item.areaname,
              type: "line",
              data: [Number(item.actualpay / 100).toFixed(2)],
              lineStyle: {
                color: this.cityColor[seriesValue.length],
              },
              itemStyle: {
                normal: {
                  color: this.cityColor[seriesValue.length],
                },
              },
            });
          }
          // 区域名称list
          if (cityNameList.indexOf(item.areaname) == -1) {
            cityNameList.push(item.areaname);
          }
        });
        this.dateList = dateList;
        this.cityNameList = cityNameList;
        this.seriesValue = seriesValue;
        this.drawGraph();
      }
    },
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let parkingRevenue = this.$echarts.init(document.getElementById("parkingRevenue"));
      let option = {
        title: {
          text: that.textTitle,
        },
        legend: {
          type: "scroll",
          left: "62px",
          data: this.cityNameList, // ["北京市", "天津市", "广州市"]
          // data: ["北京市", "天津市", "广州市", "北京市1", "天津市1", "广州市1", "北京市2", "天津市2", "广州市2", "北京市3", "天津市3", "广州市3", "北京市4", "天津市4", "广州市4"],  // ["北京市", "天津市", "广州市"]
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          // formatter: (parmas) => {
          //   console.log(parmas)
          //   let str = ''
          //   str += parmas[0].axisValue + '<br/>'
          //   parmas.forEach(e => {
          //     str += `${e.marker} ${e.seriesName}: ${formatDuring(e.value, 'seconds')}<br/>`
          //   })
          //   return str
          //   // return `${parmas.seriesName}<br/>${parmas.name}：${parmas.value}元`
          // }
          formatter: function (params) {
            if (params instanceof Array) {
              let str = "";
              str += `${params[0].axisValue}<br/>`;
              params.forEach((m, index) => {
                str += `<span class="chart-tooltip-color" style="display: inline-block; margin-right: 10px; background-color: ${m.color}; width: 10px; height: 10px; border-radius:100%; margin-right: 5px"></span>`;
                str += `${m.seriesName}：${m.value}元&nbsp;&nbsp;`;
                str += `${(index + 1) % 3 === 0 ? "<br/>" : ""}`;
              });
              return str;
            }
          },
        },
        toolbox: {
          show: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        grid: {
          left: "48px",
          right: "40px",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          name: "单位：元",
          nameGap: 25,
          // max: '100',
          // splitNumber: 10,
          // axisLabel: {
          //   formatter: function (value) {
          //     return Math.floor(value / 3600)
          //   }
          // }
        },
        series: that.seriesValue,
      };
      parkingRevenue.setOption(option, true);
    },
  },
  mounted() {
    // this.drawGraph();
    this.handleGetOverview();
  },
  computed: {
    con() {
      return "停车收入概览：按支付时间统计实收，指每个区域，支付时间在最近7天，所有车辆实收数据";
    },
  },
  created() {},
  components: {
    // graphTitle
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.parkingRevenue {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
</style>
